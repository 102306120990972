import React from 'react'
import { Fade } from 'react-reveal'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { offerModalOpen } from '../OfferModal'
import { LoadableQuintValueCard } from '../LoadableComponents'

const offerModalOptions = [
  'Mobile',
  'Web',
  'Other'
]

const ProductServices = ({ productServices }) => {
  if (!productServices) {
    return ''
  }

  const isMobile = useBreakpoint('sm', false)
  const isTablet = useBreakpoint('lg', false)

  return (
    <>
      <div
        className='content-container'
        style={{ height: isTablet ? 'unset' : '100vh', minHeight: '784px', maxHeight: isTablet ? 'unset' : 824, position: 'relative', justifyContent: 'flex-start', padding: isMobile ? 32 : 16 }}>
        {!isTablet &&
          <>
            <Fade left>
              <span
                className='quint-body font-supportive color-primary-dark display-none-on-small'
                style={{ position: 'absolute', top: '16px', left: '64px' }}>{productServices.surrounding_text}</span>
              <hr
                className='background-color-primary-medium display-none-on-small'
                style={{ position: 'absolute', top: '40px', left: '64px', right: '64px', margin: 0 }} />
              <span
                className='quint-body font-supportive color-primary-dark display-none-on-small'
                style={{
                  position: 'absolute',
                  top: '48px',
                  left: '60px',
                  writingMode: 'tb-rl',
                  transform: 'rotate(180deg)'
                }}>{productServices.surrounding_text}</span>
            </Fade>
            <Fade right>
              <span
                className='quint-body font-supportive color-primary-dark display-none-on-small'
                style={{ position: 'absolute', bottom: '16px', right: '64px' }}>{productServices.surrounding_text}</span>
              <hr
                className='background-color-primary-medium display-none-on-small'
                style={{ position: 'absolute', bottom: '40px', left: '64px', right: '64px', margin: 0 }} />
              <span
                className='quint-body font-supportive color-primary-dark display-none-on-small'
                style={{
                  position: 'absolute',
                  bottom: '48px',
                  right: '60px',
                  writingMode: 'tb-rl',
                  transform: 'rotate(180deg)'
                }}>{productServices.surrounding_text}</span>
            </Fade>
          </>}
        <div style={{ width: '100%', height: '100%', maxWidth: 1416, display: 'flex', justifyContent: 'space-between', flexDirection: isTablet ? 'column' : 'row', padding: isMobile ? 0 : '48px 72px 64px 72px' }}>
          {productServices.valuecards && productServices.valuecards.map((card, index) => (
            <LoadableQuintValueCard
              style={
                isMobile ? { height: 582, marginBottom: 72, borderWidth: 0 } : (
                  isTablet ? { height: 582, border: '1px solid #B8C3F2', borderWidth: 1, padding: 32, marginBottom: 64 }
                    : { height: '100%', maxWidth: 400 })
              }
              cardTitle={card.valuecard_title}
              cardSubtitle={card.valuecard_subtitle}
              media={card.valuecard_image && card.valuecard_image.url}
              typeText={card.valuecard_button}
              className={`button-no-outline quint-value-card-class-${index}`}
              key={index}
              id={`quint-value-card-${index}`}
              onClick={() => offerModalOpen(offerModalOptions[index])} />
          ))}
        </div>
      </div>
    </>
  )
}

export default ProductServices
