import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Fade } from 'react-reveal'
import {
  LoadableQuintProductCard,
  LoadableQuintProductCardGrid
} from '../LoadableComponents'
import Arrow from '../../assets/img/right-arrow-white.svg'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { Link } from 'gatsby'

const ProductCards = ({ products }) => {
  if (!products) {
    return ''
  }

  const isTablet = useBreakpoint('lg', false)

  return (
    <>
      {isTablet
        ? <div
          className='card-top-container'
          style={{ backgroundColor: products.backgroundColor }}>
          <div className='horizontally-scrollable-container'>
            {products.productcards && products.productcards[0] &&
              <AniLink
                id={products.productcards[0].product.slug}
                className='card-container' key={0}
                to={`/portfolio/${products.productcards[0].product.slug}`}>
                <LoadableQuintProductCard
                  icon={products.productcards[0].product.document.data.icon.url}
                  text={products.productcards[0].product.document.data.category}
                  backgroundColor={products.productcards[0].product.document.data.card_color}
                  textColor={products.productcards[0].product.document.data.text_color}
                  className='product-card'
                  enabledArrow={!!products.productcards[0].product.slug}
                />
              </AniLink>}
            {products.productcards && products.productcards[1] &&
              <AniLink
                id={products.productcards[1].product.slug}
                className='card-container' key={1}
                to={`/portfolio/${products.productcards[1].product.slug}`}>
                <LoadableQuintProductCard
                  icon={products.productcards[1].product.document.data.icon.url}
                  text={products.productcards[1].product.document.data.category}
                  backgroundColor={products.productcards[1].product.document.data.card_color}
                  textColor={products.productcards[1].product.document.data.text_color}
                  className='product-card'
                  enabledArrow={!!products.productcards[1].product.slug}
                />
              </AniLink>}
            {products.productcards && products.productcards[2] &&
              <AniLink
                id={products.productcards[2].product.slug}
                className='card-container' key={2}
                to={`/portfolio/${products.productcards[2].product.slug}`}>
                <LoadableQuintProductCard
                  icon={products.productcards[2].product.document.data.icon.url}
                  text={products.productcards[2].product.document.data.category}
                  backgroundColor={products.productcards[2].product.document.data.card_color}
                  textColor={products.productcards[2].product.document.data.text_color}
                  className='product-card'
                  enabledArrow={!!products.productcards[2].product.slug}
                />
              </AniLink>}
            {products.productcards && products.productcards[3] &&
              <AniLink
                id={products.productcards[3].product.slug}
                className='card-container' key={3}
                to={`/portfolio/${products.productcards[3].product.slug}`}>
                <LoadableQuintProductCard
                  icon={products.productcards[3].product.document.data.icon.url}
                  text={products.productcards[3].product.document.data.category}
                  backgroundColor={products.productcards[3].product.document.data.card_color}
                  textColor={products.productcards[3].product.document.data.text_color}
                  className='product-card'
                  enabledArrow={!!products.productcards[3].product.slug}
                />
              </AniLink>}
            {products.productcards && products.productcards[4] &&
              <AniLink
                id={products.productcards[4].product.slug}
                className='card-container' key={4}
                to={`/portfolio/${products.productcards[4].product.slug}`}>
                <LoadableQuintProductCard
                  icon={products.productcards[4].product.document.data.icon.url}
                  text={products.productcards[4].product.document.data.category}
                  backgroundColor={products.productcards[4].product.document.data.card_color}
                  textColor={products.productcards[4].product.document.data.text_color}
                  className='product-card'
                  enabledArrow={!!products.productcards[4].product.slug}
                />
              </AniLink>}
          </div>
        </div>
        : <div
          className='content-container display-none-on-small'
          style={{ height: '100vh', minHeight: '784px', position: 'relative' }}>

          {products.surroundingtext &&
            <>
              <Fade left>
                <span
                  className='quint-body font-supportive color-primary-dark display-none-on-small'
                  style={{
                    position: 'absolute',
                    top: '16px',
                    left: '64px'
                  }}>{products.surroundingtext}</span>
                <hr
                  className='background-color-primary-medium display-none-on-small'
                  style={{
                    position: 'absolute',
                    top: '40px',
                    left: '64px',
                    right: '64px',
                    margin: 0
                  }} />
                <span
                  className='quint-body font-supportive color-primary-dark display-none-on-small'
                  style={{
                    position: 'absolute',
                    top: '48px',
                    left: '60px',
                    writingMode: 'tb-rl',
                    transform: 'rotate(180deg)'
                  }}>{products.surroundingtext}</span>
              </Fade>
              <Fade right>
                <span
                  className='quint-body font-supportive color-primary-dark display-none-on-small'
                  style={{
                    position: 'absolute',
                    bottom: '16px',
                    right: '64px'
                  }}>{products.surroundingtext}</span>
                <hr
                  className='background-color-primary-medium display-none-on-small'
                  style={{
                    position: 'absolute',
                    bottom: '40px',
                    left: '64px',
                    right: '64px',
                    margin: 0
                  }} />
                <span
                  className='quint-body font-supportive color-primary-dark display-none-on-small'
                  style={{
                    position: 'absolute',
                    bottom: '48px',
                    right: '60px',
                    writingMode: 'tb-rl',
                    transform: 'rotate(180deg)'
                  }}>{products.surroundingtext}</span>
              </Fade>
            </>}

          <LoadableQuintProductCardGrid
            style={{
              width: '60%',
              height: '85%'
            }}
            topLeft={products.productcards && products.productcards[0] &&
              <AniLink
                id={products.productcards[0].product.slug}
                style={{ cursor: 'default' }} key={0}
                to={`/portfolio/${products.productcards[0].product.slug}`}>
                <LoadableQuintProductCard
                  icon={products.productcards[0].product.document.data.icon.url}
                  text={products.productcards[0].product.document.data.category}
                  backgroundColor={products.productcards[0].product.document.data.card_color}
                  textColor={products.productcards[0].product.document.data.text_color}
                  enabledArrow={!!products.productcards[0].product.slug}
                />
              </AniLink>}
            bottomLeft={products.productcards && products.productcards[1] &&
              <AniLink
                id={products.productcards[1].product.slug}
                style={{ cursor: 'default' }} key={1}
                to={`/portfolio/${products.productcards[1].product.slug}`}>
                <LoadableQuintProductCard
                  icon={products.productcards[1].product.document.data.icon.url}
                  text={products.productcards[1].product.document.data.category}
                  backgroundColor={products.productcards[1].product.document.data.card_color}
                  textColor={products.productcards[1].product.document.data.text_color}
                  enabledArrow={!!products.productcards[1].product.slug}
                />
              </AniLink>}
            center={products.productcards && products.productcards[2] &&
              <AniLink
                id={products.productcards[2].product.slug}
                style={{ cursor: 'default' }} key={2}
                to={`/portfolio/${products.productcards[2].product.slug}`}>
                <LoadableQuintProductCard
                  icon={products.productcards[2].product.document.data.icon.url}
                  text={products.productcards[2].product.document.data.category}
                  backgroundColor={products.productcards[2].product.document.data.card_color}
                  textColor={products.productcards[2].product.document.data.text_color}
                  enabledArrow={!!products.productcards[2].product.slug}
                />
              </AniLink>}
            topRight={products.productcards && products.productcards[3] &&
              <AniLink
                id={products.productcards[3].product.slug}
                style={{ cursor: 'default' }} key={3}
                to={`/portfolio/${products.productcards[3].product.slug}`}>
                <LoadableQuintProductCard
                  icon={products.productcards[3].product.document.data.icon.url}
                  text={products.productcards[3].product.document.data.category}
                  backgroundColor={products.productcards[3].product.document.data.card_color}
                  textColor={products.productcards[3].product.document.data.text_color}
                  enabledArrow={!!products.productcards[3].product.slug}
                />
              </AniLink>}
            bottomRight={products.productcards && products.productcards[4] &&
              <AniLink
                id={products.productcards[4].product.slug}
                style={{ cursor: 'default' }} key={4}
                to={`/portfolio/${products.productcards[4].product.slug}`}>
                <LoadableQuintProductCard
                  icon={products.productcards[4].product.document.data.icon.url}
                  text={products.productcards[4].product.document.data.category}
                  backgroundColor={products.productcards[4].product.document.data.card_color}
                  textColor={products.productcards[4].product.document.data.text_color}
                  enabledArrow={!!products.productcards[4].product.slug}
                />
              </AniLink>} />
        </div>}
      {products.footertext &&
        <Link to='/portfolio' style={{ textDecoration: 'none' }}>
          <div
            className='content-container padding-0 background-color-primary font-main quint-subtitle d-flex'
            style={{
              color: 'white',
              height: '86px',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div style={{ position: 'relative' }}>
              {products.footertext}
              {!isTablet && <img
                src={Arrow} alt='arrow' style={{
                  width: 36,
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  right: -70
                }} />}
            </div>
          </div>
        </Link>}
    </>
  )
}

export default ProductCards
