import React from 'react'
import ArrowDown from '../../assets/img/arrow-down.svg'
import { Fade } from 'react-reveal'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { LoadableQuintMediaContainer } from '../LoadableComponents'

const HomePageSection2 = ({ homeSection2 }) => {
  if (!homeSection2) {
    return ''
  }

  const isMobile = useBreakpoint('sm', false)

  return (
    <div className='content-container' style={{ paddingTop: isMobile ? 0 : '20vh', paddingBottom: '20vh', position: 'relative', overflowX: 'hidden' }}>
      <div className='content-container' style={{ maxWidth: '720px' }}>
        {homeSection2.heading ? (
          <Fade bottom><h2 className='quint-heading font-main color-primary text-center' style={{ marginBottom: 24 }}>
            {homeSection2.heading}
          </h2></Fade>
        ) : (
          ''
        )}
        {homeSection2.subtitle ? <Fade bottom><h4 className='quint-subtitle font-main color-primary text-center'>{homeSection2.subtitle}</h4></Fade> : ''}
      </div>
      {homeSection2.medialeft && homeSection2.medialeft.document && homeSection2.medialeft.document ? (
        <LoadableQuintMediaContainer
          type={homeSection2.medialeft.document.data.type}
          path={homeSection2.medialeft.document.data.file.url}
          mediaSpeed={homeSection2.medialeft.document.data.speed}
          style={{ position: 'absolute', left: '-100px', top: 'calc(50% - 100px)', height: '200px', width: '200px' }}
          className='display-none-on-small rotate animated very-slow infinite'
        />
      ) : (
        ''
      )}
      {homeSection2.mediaright && homeSection2.mediaright.document && homeSection2.mediaright.document ? (
        <LoadableQuintMediaContainer
          type={homeSection2.mediaright.document.data.type}
          path={homeSection2.mediaright.document.data.file.url}
          mediaSpeed={homeSection2.mediaright.document.data.speed}
          style={{ position: 'absolute', right: '-70px', top: 'calc(20% - 70px)', height: '140px', width: '140px' }}
          className='display-none-on-small rotate animated very-slow infinite'
        />
      ) : (
        ''
      )}
      <img
        className='bounce-simple animated medium infinite'
        src={ArrowDown}
        alt=''
        style={{ position: 'absolute', bottom: '64px', left: 'calc(50% - 6px)' }}
      />
    </div>
  )
}

export default HomePageSection2
