import React from 'react'
import { graphql } from 'gatsby'
import HomePageTemplate from '../components/HomePageTemplate'

function createSectionData(data) {
  const dataObject = {}
  Object.keys(data).forEach(value => {
    const sectionName = value.split(/_(.+)/)[0]
    const keyName = value.split(/_(.+)/)[1]
    if (dataObject[sectionName]) {
      dataObject[sectionName][keyName] = data[value]
    } else {
      dataObject[sectionName] = {}
      dataObject[sectionName][keyName] = data[value]
    }
  })
  return dataObject
}

const HomePage = ({ data }) => {
  if (!data || !data.prismicHomePage) {
    return ''
  }
  const { data: pageData } = data.prismicHomePage
  const sectionData = createSectionData(pageData)

  return (
    <HomePageTemplate
      meta_title={sectionData.meta ? sectionData.meta.title : null}
      meta_description={sectionData.meta ? sectionData.meta.description : null}
      meta_image={sectionData.meta && sectionData.meta.image ? sectionData.meta.image.url : null}
      homeSection1={sectionData.section1}
      homeSection2={sectionData.section2}
      homeSection3={sectionData.section3}
      homeSection4={sectionData.section4}
      productServices={sectionData.productservices}
      testimonials={sectionData.testimonials}
      products={sectionData.products}
    />
  )
}

export default HomePage

export const pageQuery = graphql`
    query HomePage($id: String!) {
        prismicHomePage(id: {eq: $id}) {
            data {
                meta_title
                meta_description
                meta_image {
                  url
                }
                section1_heading
                section1_subtitle
                section1_button
                section1_icons {
                  section1_icon {
                    url
                    alt
                  }
                }
                section1_media {
                  document {
                    ... on PrismicMedia {
                      id
                      data {
                        file {
                          url
                        }
                        speed
                        type
                      }
                    }
                  }
                }
                section1_espa_pdf {
                  url
                }
                productservices_heading
                productservices_subtitle
                productservices_mobile
                productservices_web
                productservices_team
                productservices_choose
                productservices_surrounding_text
                productservices_valuecards {
                  valuecard_title
                  valuecard_subtitle
                  valuecard_image {
                    url
                  }
                  valuecard_button
                }
                section2_heading
                section2_subtitle
                section2_medialeft {
                  document {
                    ... on PrismicMedia {
                      id
                      data {
                        file {
                          url
                        }
                        speed
                        type
                      }
                    }
                  }
                }
                section2_mediaright {
                  document {
                    ... on PrismicMedia {
                      id
                      data {
                        file {
                          url
                        }
                        speed
                        type
                      }
                    }
                  }
                }
                products_productcards {
                  product {
                    slug
                    document {
                      ... on PrismicProduct {
                        id
                        data {
                          category
                          text_color
                          card_color
                          icon {
                            url
                            alt
                          }
                        }
                      }
                    }
                  }
                }
                products_surroundingtext
                products_footertext
                testimonials_heading
                testimonials_testimonialslist {
                  testimonial {
                    document {
                      ... on PrismicTestimonial {
                        id
                        data {
                          author
                          quote
                          job_title
                          company_logo {
                            url
                            alt
                          }
                        }
                      }
                    }
                  }
                }
                testimonials_timeinterval
                section4_heading
                section4_subtitle
                section4_communities {
                    text
                    details
                    button
                    icon {
                        url
                    }
                    url {
                        url
                    }
                }
                section3_surroundingtext
                section3_iconbuttons {
                    icon {
                        url
                    }
                    url {
                        url
                    }
                }
            }
        }
    }
`
