import React from 'react'
import Testimonials from '../Testimonials'
import ProductCards from '../ProductCards'
import HomePageSection1 from '../HomePageSection1'
import HomePageSection2 from '../HomePageSection2'
import ProductServices from '../ProductServices'
import HomePageSection4 from '../HomePageSection4'
import Seo from '../SEO'

const HomePageTemplate = ({
  meta_title,
  meta_description,
  meta_image,
  homeSection1,
  homeSection2,
  homeSection4,
  productServices,
  testimonials,
  products,
}) => (
  <div>
    <Seo title={meta_title} description={meta_description} image={meta_image} />
    <HomePageSection1 homeSection1={homeSection1} />
    <ProductServices productServices={productServices} />
    <HomePageSection2 homeSection2={homeSection2} />
    <ProductCards products={products} />
    <Testimonials testimonials={testimonials} />
    <HomePageSection4 data={homeSection4} />
  </div>
)

export default HomePageTemplate
