import React, { useEffect, useRef } from 'react'
import { Fade } from 'react-reveal'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { contactModalOpen } from '../ContactModal'
import { offerModalOpen } from '../OfferModal'
import 'quint-ui-react/dist/index.css'
import EspaQuint from '../../assets/img/espa-quint.png'
import { LoadableQuintButton, LoadableQuintMediaContainer } from '../LoadableComponents'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const HomePageSection1 = ({ homeSection1 }) => {
  if (!homeSection1) {
    return ''
  }

  const isMobile = useBreakpoint('sm', false)
  const isTablet = useBreakpoint('lg', false)

  const imageRef = useRef()

  useEffect(() => {
    if (isMobile) return
    gsap.to(imageRef.current, {
      scrollTrigger: {
        trigger: imageRef.current,
        scrub: true,
      },
      y: 50,
      autoAlpha: 0,
      ease: 'slow',
    })
  }, [imageRef, isMobile])

  return (
    <div
      className='content-container'
      style={{
        minHeight: `calc(100vh - 85px)`,
        position: 'relative',
        justifyContent: isMobile ? 'flex-start' : 'center',
        marginTop: 85,
        padding: 0
      }}>
      {/* <div */}
      {/*  style={{ */}
      {/*    width: '100%', */}
      {/*    height: 56, */}
      {/*    backgroundColor: '#25317B', */}
      {/*    display: 'flex', */}
      {/*    justifyContent: 'center', */}
      {/*    alignItems: 'center', */}
      {/*    position: 'relative', */}
      {/*    padding: isMobile ? 16 : 0, */}
      {/*    cursor: 'pointer' */}
      {/*  }} onClick={() => navigate('https://zoom.us/webinar/register/2715936273869/WN_gK8sZzDlQQi02Y1ta9O4bw')}> */}
      {/*  <div className='font-main quint-text-regular color-white'> */}
      {/*    Join us on the first ever Figma Athens Online Global Event, Friday July 10th, 19:00 GMT+3 */}
      {/*  </div> */}
      {/*  <img */}
      {/*    style={{ */}
      {/*      width: 25, */}
      {/*      position: isTablet ? 'initial' : 'absolute', */}
      {/*      marginLeft: isTablet ? 64 : 0, */}
      {/*      right: 120 */}
      {/*    }} */}
      {/*    src={RightArrow} */}
      {/*    alt='quint-white-arrow' */}
      {/*  /> */}
      {/* </div> */}

      <div style={isTablet ? { padding: 32 } : {
        maxWidth: 1320,
        padding: '32px 48px',
        width: '100%'
      }}>
        <div
          style={
            !isTablet
              ? {
                minHeight: 616,
                justifyContent: 'space-between',
                width: '100%',
                display: 'flex',
                alignItems: 'center'
              }
              : { marginBottom: 32 }
          }>
          <div className='d-flex flex-column' style={!isTablet ? { width: '40%' } : {}}>
            {homeSection1.heading
              ? <Fade bottom>
                <h2
                  className='quint-heading-big font-main color-primary'
                  style={isTablet ? {
                    marginBottom: '42px',
                    fontSize: '36px',
                    textAlign: 'start',
                    maxWidth: 240
                  } : { marginBottom: '42px', maxWidth: 420 }}>
                  {homeSection1.heading}
                </h2>
              </Fade>
              : ''}
            {homeSection1.subtitle
              ? <Fade bottom>
                <h4
                  className='quint-subtitle font-main color-primary-dark'
                  style={isTablet ? {
                    marginBottom: 48,
                    textAlign: 'start'
                  } : { marginBottom: 32, maxWidth: 420 }}>
                  {homeSection1.subtitle}
                </h4>
              </Fade>
              : ''}
            {homeSection1.button
              ? <Fade bottom>
                <div style={isTablet ? { marginBottom: 16 } : {}}>
                  <LoadableQuintButton
                    boxShadow rounded='contained' border textColor='#ffffff'
                    background='#25317B'
                    width={isTablet ? '100%' : '220px'}
                    className='quint-button font-main text-align-center-on-small button-no-outline'
                    onClick={() => contactModalOpen()}>
                    {homeSection1.button}
                  </LoadableQuintButton>
                </div>
              </Fade>
              : ''}
            {homeSection1.button
              ? isTablet && <Fade bottom>
                <div style={{ marginBottom: isTablet ? 64 : 32 }}>
                  <LoadableQuintButton
                    boxShadow='true' rounded='contained' border textColor='#25317B'
                    background='#ffffff'
                    width='100%' box-shadow-color='#25317B'
                    className='quint-button font-main text-align-center-on-small'
                    onClick={() => offerModalOpen()}>
                    Estimate project
                  </LoadableQuintButton>
                </div>
              </Fade>
              : ''}
          </div>
          {!isTablet && homeSection1.media && homeSection1.media.document && homeSection1.media.document ? (
            <LoadableQuintMediaContainer
              type={homeSection1.media.document.data.type}
              path={homeSection1.media.document.data.file && homeSection1.media.document.data.file.url}
              mediaSpeed={homeSection1.media.document.data.speed}
              style={{
                height: '100%',
                maxHeight: '100vh',
                width: '40%',
                marginLeft: '5%'
              }}
            />
          ) : (
            ''
          )}
        </div>
        {homeSection1.icons &&
          <div className='d-flex flex-column w-100'>
            <Fade bottom>
              <div
                className='quint-supportive font-supportive color-primary'
                style={{ marginBottom: 8 }}>
                As trusted for their services by:
              </div>
              <div style={{ border: '1px solid #B7C2F6', marginBottom: 32 }} />
              <div
                className='d-flex flex-row align-items-center'
                style={isTablet ? { flexWrap: 'wrap' } : { justifyContent: 'space-between' }}>
                {homeSection1.icons.map((icon, index) => {
                  return (
                    <img
                      src={icon.section1_icon.url} alt={icon.section1_icon.alt}
                      style={isTablet ? {
                        width: '33%',
                        height: 'fit-content'
                      } : { height: 'fit-content' }} key={index} />
                  )
                })}
              </div>
            </Fade>
          </div>}
      </div>
      <a
        href={homeSection1.espa_pdf.url} target='_blank' rel='noreferrer' ref={imageRef} style={isMobile ? {
          width: '100%',
          padding: '0 32px',
        } : {
          position: 'absolute',
          bottom: 16,
          right: 16,
          height: 48,
          cursor: 'pointer',
          zIndex: 1
        }}>
        <img
          src={EspaQuint}
          alt=''
          style={isMobile ? { width: '100%' } : { height: '100%' }}
        />
      </a>
    </div>
  )
}

export default HomePageSection1
