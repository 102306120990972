import React from 'react'
import { Fade } from 'react-reveal'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { QuintUnderlineAnimated } from 'quint-ui-react'

const HomePageSection4 = ({ data }) => {
  if (!data) {
    return ''
  }

  const isMobile = useBreakpoint('lg', false)

  return (
    <div className='content-container' style={isMobile ? { height: 'unset' } : { minHeight: '100vh' }}>
      <div className='content-container quint-container-xl' style={{ overflowY: 'hidden' }}>
        <Fade bottom>
          {data.heading &&
            <div className='text-center quint-heading font-main color-primary' style={{ marginBottom: 24, marginTop: isMobile ? 64 : 0 }}>
              {data.heading}
            </div>}
        </Fade>
        <Fade bottom>
          {data.subtitle &&
            <div className='text-center quint-subtitle font-main color-primary' style={{ maxWidth: 832, marginBottom: 96, lineHeight: '24px' }}>
              {data.subtitle}
            </div>}
        </Fade>
        <div style={{ display: 'flex', flexWrap: isMobile ? 'no-wrap' : 'wrap', maxWidth: 1150, justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row' }}>
          {data.communities && data.communities.map((item, index) => {
            return (
              <Fade bottom key={index}>
                <div
                  style={{
                    height: isMobile ? 'unset' : 232,
                    maxWidth: 560,
                    width: '100%',
                    border: '1px solid #B8C3F2',
                    borderRadius: '2px',
                    padding: 32,
                    marginBottom: 32,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: isMobile ? 'center' : 'flex-start',
                    justifyContent: 'space-between'
                  }}>
                  <img src={item.icon.url} alt='quint-github' style={{ height: isMobile ? 24 : 32, maxHeight: isMobile ? 24 : 32, marginBottom: 16 }} />
                  <div className='quint-button font-main color-primary' style={{ marginBottom: 8, fontSize: isMobile ? 24 : 16, lineHeight: 1 }}>
                    {item.text}
                  </div>
                  <div className='quint-text-regular font-main color-primary' style={{ marginBottom: 24, lineHeight: '16px' }}>
                    {item.details}
                  </div>
                  <div style={{ width: 'fit-content', cursor: 'pointer' }}>
                    <QuintUnderlineAnimated lineColor='#25317B' style={{ cursor: 'pointer' }} onClick={item.url ? () => window.open(item.url.url) : null}>
                      <span className='quint-button font-main color-primary'>
                        {item.button}
                      </span>
                    </QuintUnderlineAnimated>
                  </div>
                </div>
              </Fade>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default HomePageSection4
