import React from 'react'
import { Fade } from 'react-reveal'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { LoadableQuintCarousel, LoadableQuintTestimonial } from '../LoadableComponents'

const Testimonials = ({ testimonials }) => {
  if (!testimonials || !testimonials.testimonialslist || !testimonials) {
    return ''
  }

  const isMobile = useBreakpoint('sm', false)
  const isTablet = useBreakpoint('lg', false)

  const mobileHeight = 754

  return (
    <div className='content-container padding-0' style={{ height: isMobile ? mobileHeight : '70vh', backgroundColor: '#FAFAFA' }}>
      <Fade>
        <div style={{ flex: 1, width: '100%' }}>
          <LoadableQuintCarousel
            interval={testimonials.timeinterval ? testimonials.timeinterval : 15000} height={isMobile ? mobileHeight : '70vh'} arrows={!isMobile}>
            {testimonials.testimonialslist.map((testimonial, id) => (
              <div
                key={id}
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <LoadableQuintTestimonial
                  centered
                  icon={testimonial.testimonial.document.data.company_logo.url}
                  style={{ padding: isMobile ? '64px 32px' : '116px 0', backgroundColor: '#FAFAFA' }}
                  content={<p
                    className='color-primary'
                    style={{
                      maxWidth: isTablet ? 800 : 1088,
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: isMobile ? 16 : 24,
                      lineHeight: '29px',
                      letterSpacing: 'normal'
                    }}>{testimonial.testimonial.document.data.quote}
                  </p>}
                  name={<span
                    className='color-primary'
                    style={{
                      marginBottom: isTablet ? 8 : 16,
                      fontSize: isMobile ? 16 : 24,
                      letterSpacing: 'normal'
                    }}>
                    {testimonial.testimonial.document.data.author}
                  </span>}
                  jobDescription={<span
                    className='color-primary-medium'
                    style={{
                      marginTop: 0,
                      fontSize: isMobile ? 16 : 24,
                      letterSpacing: 'normal'
                    }}>
                    {testimonial.testimonial.document.data.job_title}
                  </span>} />
              </div>
            ))}
          </LoadableQuintCarousel>
        </div>
      </Fade>
    </div>
  )
}

export default Testimonials
